.pedido{
    background-color: #FFF;
    min-height: 100px;
    border-radius: 10px; 
    margin-bottom: 30px;   
    border: 1px solid rgb(222, 222, 222);
    padding: 15px;
}

.pedido a{
    cursor: pointer;
}

.foto-item{
    max-width: 60px;    
}

.img-produto{
    max-width: 50px; 
}